import { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import DiContext from '@aveneo/frontmoon-di/lib';
import noop from 'lodash/noop';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { emailValidation, minLength } from '@aveneo/frontmoon-utils/lib/form';
import { phoneValidation } from 'src/utils/validators';

import { selectUserRestaurantSlug } from 'src/modules/User';
import LoginCode from 'src/components/LoginCode';
import Button from 'src/components/Button';

import theme from 'src/styles/components/UserLoginForm.module.scss';

import { LOGIN_METHOD_DETECTED } from './detectors';

const loginMinLength = (value) => minLength([5], 'App.emailOrPhoneMinLength', value);

const UserLoginForm = ({
    login,
    code,
    isLoggingIn,
    isLoggedIn,
    onLogin,
    loginError,
    loginMethod,
    loginValue,
    valid,
    generateSignInCodeByUser,
    clearLoginForm,
    isSignInCodeGenerated,
    signInRestaurantId
}) => {
    const intl = useIntl();
    const context = useContext(DiContext);
    const { InputForm } = context.container;

    const restaurantSlug = useSelector(selectUserRestaurantSlug);

    useEffect(() => () => {
        clearLoginForm();
    }, []);

    const handleLogin = useCallback((loginCode) => {
        login(loginCode, code, signInRestaurantId);
    }, [code, signInRestaurantId]);

    const handleSubmitLoginName = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!loginMethod.key) {
            return;
        }

        if (loginMethod.key === LOGIN_METHOD_DETECTED.EMAIL.key) {
            generateSignInCodeByUser({
                email: loginValue,
                authorizationMethod: loginMethod.value
            });
        }

        if (loginMethod.key === LOGIN_METHOD_DETECTED.PHONE.key) {
            generateSignInCodeByUser({
                phoneNumber: loginValue,
                authorizationMethod: loginMethod.value
            });
        }

        return false;
    }, [loginMethod.value, loginValue]);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!isSignInCodeGenerated) {
            handleSubmitLoginName(event);
        }

        return false;
    }, [isSignInCodeGenerated]);

    useEffect(() => {
        if (isLoggedIn && restaurantSlug) {
            onLogin(restaurantSlug);
        }
    }, [isLoggedIn, restaurantSlug]);

    const getValidator = useCallback(() => {
        if (loginMethod.key === LOGIN_METHOD_DETECTED.EMAIL.key) {
            return emailValidation;
        }

        if (loginMethod.key === LOGIN_METHOD_DETECTED.PHONE.key) {
            return phoneValidation;
        }

        return loginMinLength;
    }, [loginMethod.key]);

    return (
        <form
          className={theme.container}
          noValidate="novalidate"
          onSubmit={handleSubmit}
        >
            <div className={theme.content}>
                {loginMethod.key && intl.formatMessage({ id: `App.${loginMethod.key}Detected` })}
                <InputForm
                  containerClassName={theme.loginInputContainer}
                  className={theme.loginInput}
                  name="login"
                  placeholder={intl.formatMessage({ id: 'App.emailOrPhone' })}
                  validate={[getValidator()]}
                  disabled={isSignInCodeGenerated}
                />
                {isLoggingIn && intl.formatMessage({ id: 'App.verification' })}
                {isSignInCodeGenerated && <LoginCode onSubmit={handleLogin} />}
                <div className={theme.invalidCode}>
                    {loginError && intl.formatMessage({ id: `Error.${loginError}` })}
                </div>
                {!isSignInCodeGenerated && (
                    <Button
                      raised
                      accent
                      disabled={isSignInCodeGenerated || !valid}
                      label={intl.formatMessage({ id: 'App.sendOneTimeCode' })}
                      onClick={handleSubmitLoginName}
                    />
                )}
                {isSignInCodeGenerated && (
                    <Button
                      raised
                      neutral
                      label={intl.formatMessage({ id: 'App.loginOnDifferentAccount' })}
                      onClick={clearLoginForm}
                    />
                )}
            </div>
        </form>
    );
};

UserLoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    code: PropTypes.string.isRequired,
    isLoggingIn: PropTypes.bool.isRequired,
    formData: PropTypes.shape({
        values: PropTypes.shape({
            loginCode: PropTypes.string
        })
    }),
    isLoggedIn: PropTypes.bool.isRequired,
    loginError: PropTypes.string,
    onLogin: PropTypes.func,
    loginMethod: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number
    }).isRequired,
    valid: PropTypes.bool,
    generateSignInCodeByUser: PropTypes.func.isRequired,
    loginValue: PropTypes.string,
    clearLoginForm: PropTypes.func.isRequired,
    isSignInCodeGenerated: PropTypes.bool,
    signInRestaurantId: PropTypes.string
};

UserLoginForm.defaultProps = {
    onLogin: noop
};

export default UserLoginForm;
