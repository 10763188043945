import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

export const required = (value) => {
    if (typeof value === 'boolean' || typeof value === 'string') {
        return value ? undefined : 'Core.required';
    }

    return !isUndefined(value) && !isNull(value) ? undefined : 'Core.required';
};

export const phoneValidation = (value) => {
    if (value) {
        const result = /^\+?\d+$/.test(value);

        if (!result) {
            return 'Core.phoneValidationError';
        }
    }

    return undefined;
};
