import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
    login,
    selectIsLoggingIn,
    selectIsLoggedIn,
    selectUserRestaurant,
    selectLoginError,
    generateSignInCodeByUser,
    clearLoginForm,
    selectIsSignInCodeGenerated,
    selectSignInRestaurantId
} from 'src/modules/User';

import { emitMessage, dismissFirstMessage } from 'src/modules/Modal';

import { FORM_ID } from './constants';
import { loginMethodDetector } from './detectors';

const mapDispatchToProps = {
    login,
    emitMessage,
    dismissFirstMessage,
    generateSignInCodeByUser,
    clearLoginForm
};

const loginFormSelector = formValueSelector(FORM_ID);

const mapStateToProps = (state) => {
    const loginValue = loginFormSelector(state, 'login');

    return ({
        isLoggingIn: selectIsLoggingIn(state),
        isLoggedIn: selectIsLoggedIn(state),
        loginError: selectLoginError(state),
        restaurant: selectUserRestaurant(state),
        loginMethod: loginMethodDetector(loginValue),
        loginValue,
        isSignInCodeGenerated: selectIsSignInCodeGenerated(state),
        signInRestaurantId: selectSignInRestaurantId(state)
    });
};

export default connect(mapStateToProps, mapDispatchToProps);
