import { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import DiContext from '@aveneo/frontmoon-di/lib';
import times from 'lodash/times';
import throttle from 'lodash/throttle';

import theme from 'src/styles/components/LoginForm.module.scss';

const LoginForm = ({
    codeLength,
    onSubmit
}) => {
    const context = useContext(DiContext);
    const { Input } = context.container;

    const handleSubmit = useCallback(throttle((value) => {
        onSubmit(value);
    }, 2000), []);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Backspace') {
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;

            const prevIndex = parseInt(event.target.dataset.code, 10) - 1;

            if (prevIndex >= 0) {
                const nextElement = document.querySelector(`[data-code="${prevIndex}"]`);

                nextElement.focus();
            }

            return;
        }

        const inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
            if (event.target.type === 'text') {
                event.preventDefault();
            }
        }
    });

    const handleChange = useCallback((value, event) => {
        const nextIndex = parseInt(event.target.dataset.code, 10) + 1;

        if (/[0-9]/.test(value)) {
            if (event.target.type === 'text') {
                event.preventDefault();
            }

            if (nextIndex < codeLength) {
                const nextElement = document.querySelector(`[data-code="${nextIndex}"]`);

                nextElement.focus();
            } else {
                const codeInputs = document.querySelectorAll('[data-code]');

                const loginCode = Array.prototype.slice.call(codeInputs).reduce((result, element) => result + element.value, '');

                if (loginCode.length === codeLength) {
                    handleSubmit(loginCode);
                }
            }
        }
    });

    const handlePaste = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();

        const loginCode = event.clipboardData.getData('text/plain').replace(/\D/g, '');

        if (loginCode.length === codeLength) {
            const codeInputs = document.querySelectorAll('[data-code]');

            // eslint-disable-next-line no-param-reassign
            Array.prototype.slice.call(codeInputs).forEach((element, index) => { element.value = loginCode[index]; });

            onSubmit(loginCode);
        }
    });

    return (
        <div className={theme.loginCodeContainer}>
            {times(codeLength, (number) => (
                <Input
                  key={number}
                  className={theme.input}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  data-code={number}
                  maxLength={1}
                  shouldShowCounter={false}
                  onPaste={handlePaste}
                  type="number"
                />
            ))}
        </div>
    );
};

LoginForm.propTypes = {
    codeLength: PropTypes.number,
    onSubmit: PropTypes.func
};

LoginForm.defaultProps = {
    codeLength: 6
};

export default LoginForm;
