import Head from 'next/head';
import PanelLayout from 'src/layouts/PanelLayout';
import { useEffect, useCallback } from 'react';
import Router from 'next/router';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { dismissFirstMessage, emitMessage, MESSAGE_TYPE } from 'src/modules/Modal';
import { selectIsLoggedIn, selectUserContextRestaurantSlug } from 'src/modules/User';

import UserLoginForm from 'src/components/UserLoginForm';

const LoginPage = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const userContextRestaurantSlug = useSelector(selectUserContextRestaurantSlug);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        if (isLoggedIn && userContextRestaurantSlug) {
            Router.push(`/restaurant/${userContextRestaurantSlug}/tables`);
        }
    }, [isLoggedIn, userContextRestaurantSlug]);

    const handleLogin = useCallback((slug) => {
        dispatch(dismissFirstMessage());

        Router.push(`/restaurant/${slug}/tables`);
    });

    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(emitMessage({
                title: intl.formatMessage({ id: 'App.emailAndPhoneLogin' }),
                subtitle: intl.formatMessage({ id: 'App.login' }),
                message: (
                    <UserLoginForm
                      onLogin={handleLogin}
                    />
                ),
                type: MESSAGE_TYPE.XS,
                isEscKeyDownEnabled: false,
                isDismissButtonEnabled: false,
                isBackdropClickEnabled: false,
                hasFixedContent: true
            }));
        }
    }, [isLoggedIn]);

    return (
        <>
            <Head>
                <title>apetit.io</title>
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <PanelLayout
              isMenuEnabled={false}
              isHeaderVisible={false}
            />
        </>
    );
};

export default LoginPage;
