import compose from '@aveneo/frontmoon-utils/lib/hocs/compose';
import { withRouter } from 'next/router';
import { reduxForm } from 'redux-form';
import UserLoginForm from './UserLoginForm';
import connectContainer from './UserLoginFormContainer';
import { FORM_ID } from './constants';

export default compose(
    reduxForm({ form: FORM_ID }),
    connectContainer,
    withRouter
)(UserLoginForm);
