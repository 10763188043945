const MIN_STIRNG_LENGTH_TO_TEST = 5;

export const LOGIN_METHOD_DETECTED = {
    EMAIL: {
        key: 'email',
        value: 0
    },
    PHONE: {
        key: 'phone',
        value: 1
    }
};

export const loginMethodDetector = (value) => {
    if (!value) {
        return {};
    }

    const valueToTest = value.trim();

    if (valueToTest.length < MIN_STIRNG_LENGTH_TO_TEST) {
        return {};
    }

    if ((/^\+?\d+$/).test(valueToTest)) {
        return LOGIN_METHOD_DETECTED.PHONE;
    }

    return LOGIN_METHOD_DETECTED.EMAIL;
};
